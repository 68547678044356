import React from 'react';
import { NonprofitSearch } from '../../components';
import { validateSubscription } from '../../util/validateSubsccription';
import css from './Hero.css';

export const Hero = ({ currentUser }) => {
  const hasValidSubscription = validateSubscription(currentUser);
  return (
    <div className={css.root}>
      <div className={css.content}>
        <h1 className={css.headLine}>Meet our nonprofits and their inspiring missions.</h1>
        <h2 className={css.subHeader}>
          Givsly offers a new way for companies to engage with nonprofit organizations. Our advertising and marketing tools help drive performance and meet business goals while supporting nonprofits who align with your corporate values.
        </h2>
        {hasValidSubscription && <NonprofitSearch className={css.search} id="hero" />}
        <p className={css.footNote}>
          {hasValidSubscription ? 'Or' : 'As a nonprofit, '}{' '}
          <a href="mailto:nonprofits@givsly.com">contact us</a>
          {' '}to add your organization to Givsly.
        </p>
      </div>
      <div className={css.image} />
    </div>
  );
};
