import React from 'react';
import css from '../LandingPage.css';
import { FormattedMessage } from 'react-intl';

import ParamountLogoURL from './paramount-logo.svg';
import TradeDeskLogoURL from './the-trade-desk-logo.svg';
import NBALogoURL from './nba-logo.svg';
import MaybellineLogoURL from './maybelline-logo.svg';
import DunkinLogoURL from './dunkin-logo.svg';
import PublicisLogoURL from './publicis-logo.svg';
import OMGLogoURL from './omg-logo.svg';
import LandOLakesLogoURL from './landolakes-logo.svg';

const LandingPageCompanies = () => {
  return (
    <>
      <p>
        <FormattedMessage id="LandingPage.companies.text" />
      </p>
      <div className={css['section-companies-row']}>
        <img src={ParamountLogoURL} alt="Paramount logo" />
        <img src={TradeDeskLogoURL} alt="TheTradeDesk logo" />
        <img src={NBALogoURL} alt="NBA logo" />
        <img src={MaybellineLogoURL} alt="Maybelline logo" />
        <img src={DunkinLogoURL} alt="Dunkin logo" />
        <img src={PublicisLogoURL} alt="Publicis logo" />
        <img src={OMGLogoURL} alt="OMG logo" />
        <img src={LandOLakesLogoURL} alt="LandOLakes logo" />
      </div>
    </>
  );
};

export default LandingPageCompanies;
