import React from 'react';
import { array, bool, func, object, shape } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import config from '../../config';
import {
  FeaturedNonprofitsV2,
  Footer,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  PrimaryButton,
  SecondaryButton,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/givslyFacebook-1200x630.png';
import twitterImage from '../../assets/givslyTwitter-600x314.png';

import css from './LandingPage.css';

import { fetchAllNonprofits, queryNonProfits } from '../../ducks/NonprofitListing.duck';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { ensureListing } from '../../util/data';

import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { updateProfile } from '../MyProfilePage/MyProfilePage.duck';
import { showOwnListing } from '../../ducks/ownListing.duck';

import { loadListings } from '../../ducks/GivslyCMS.duck';
import LandingPageCompanies from './Sections/LandingPageCompanies';
import LandingPageFeatureGoodAdvertising from './Sections/LandingPageFeatureGoodAdvertising';
import LandingPageFeatureGoodMarketing from './Sections/LandingPageFeatureGoodMarketing';
import AlertBanner from '../../components/AlertBanner/AlertBanner';

export function LandingPageComponent(props) {
  const { getListing, history, location, match } = props;
  const { intl, nonprofitListingIds, nonprofitLoadingInProgress, scrollingDisabled } = props;

  const getTranslation = React.useCallback(
    (name, variables = {}) => {
      return intl.formatMessage({ id: `LandingPage.${name}` }, variables);
    },
    [intl]
  );

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="LandingPage"
            history={history}
            location={location}
            match={match}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.layoutMainWrapper}>
          {/* <AlertBanner /> */}
          <div className={css['hero-2024-background']}>
            <div className={classNames(css['hero-2024'], css.section)}>
              <div className={classNames(css.sectionContent, css['hero-2024-section'])}>
                <div className={css['hero-2024-section-content-text']}>
                  <h1>{getTranslation('hero.title')}</h1>
                  <p>{getTranslation('hero.subtext')}</p>

                  <a href="https://form.typeform.com/to/yKoPyBT9"
                    target="_blank" rel="noopener noreferrer"
                    className={css['hero-2024-link']} >
                    <SecondaryButton className={css['hero-2024-link-button']}>
                      {getTranslation('hero.cta')}
                    </SecondaryButton>
                  </a>

                </div>
              </div>
            </div>
          </div>
          
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={classNames(css.sectionContent, css['section-companies'])}>
                <LandingPageCompanies />
              </div>
            </li>

            <li className={css.section}>
              <div className={classNames(css.sectionContent)}>
                <LandingPageFeatureGoodAdvertising />
              </div>
            </li>

            <li className={css.section}>
              <div className={classNames(css.sectionContent)}>
                <LandingPageFeatureGoodMarketing />
              </div>
            </li>

            {!nonprofitLoadingInProgress ? (
              <li className={classNames(css.section, css['section-2022-npo'])}>
                <div className={css.sectionContent}>
                  <FeaturedNonprofitsV2
                    cardClassName={css.featuredNonprofitCard}
                    className={css.featuredNonprofits}
                    isFeaturedCategory={false}
                    name="featuredNonprofits"
                    history={history}
                    nonprofits={nonprofitListingIds.map((id) => {
                      return ensureListing(getListing(id));
                    })}
                    title={getTranslation('browseNonprofitsTitle')}
                    copy={getTranslation('browseNonprofitsCopy')}
                  />
                </div>
              </li>
            ) : null}

            <li className={classNames(css.section, css['section-2022-last'])}>
              <div className={css.sectionContent}>
                <div className={css['section-2022-get-started-outline']}>
                  <div className={css['section-2022-get-started']}>
                    <h2>Ready to get started?</h2>
                    <p>
                    Request a meeting with us to see Givsly in action and incorporate values into your advertising and marketing strategies.
                    </p>
                    <div className={css['section-2022-get-started-row']}>
                      <a href="https://company.givsly.com/marketing/" target="_blank" rel="noopener noreferrer">
                        <PrimaryButton>I'm interested in Responsible Marketing</PrimaryButton>
                      </a>
                      <a href="https://company.givsly.com/advertising/" target="_blank" rel="noopener noreferrer">
                        <PrimaryButton>I'm interested in Responsible Advertising</PrimaryButton>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

LandingPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  currentUserListing: propTypes.ownListing,
  getListing: func.isRequired,
  history: shape({
    push: func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  isAuthenticated: bool,
  listingIds: array,
  listingLoadingInProgress: bool,
  location: object.isRequired,
  nonprofitListingIds: array,
  nonprofitLoadingInProgress: bool,
  nonprofitNameMap: object,
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  onUpdateProfile: func.isRequired,
};

LandingPageComponent.defaultProps = {
  currentUserListing: null,
  nonprofitListingIds: [],
  nonprofitLoadingInProgress: true,
  listingIds: [],
  listingLoadingInProgress: true,
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.Auth;
  const {
    currentPageResultIds: nonprofitListingIds,
    nonprofitNameMap,
    searchInProgress: nonprofitLoadingInProgress,
  } = state.NonprofitListing;

  const { currentPageResultIds: listingIds, listingsInProgress: listingLoadingInProgress } =
    state.Listings;

  const { ownListing } = state.ownListing;

  const getListing = (id) => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const { currentUser } = state.user;

  const { monthlyCauses } = state.GivslyCMS;

  return {
    currentUser,
    currentUserListing: ownListing,
    getListing,
    isAuthenticated: isAuthenticated,
    nonprofitListingIds,
    nonprofitLoadingInProgress,
    nonprofitNameMap,
    listingIds,
    listingLoadingInProgress,
    scrollingDisabled: isScrollingDisabled(state),
    monthlyCauses,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateProfile: (data) => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

LandingPage.loadData = () => async (dispatch, _getState) => {
  dispatch(showOwnListing()).catch(() => {
    // Non-essential, most likely the user is not authenticated
  });

  return Promise.all([
    dispatch(fetchAllNonprofits()),
    dispatch(queryNonProfits({}, 3)),
    dispatch(loadListings()),
  ]);
};

export default LandingPage;
