import React, { useState } from 'react';
import classNames from 'classnames';
import css from '../LandingPage.css';
import { FormattedMessage } from 'react-intl';
import { SecondaryButton } from '../../../components';

import GaPromoImageURL from './ga-promo-image.png';
import GaPromoImageURL_webp from './ga-promo-image.webp';
import GaTabImageSustainableSupplyURL from './good-ad-tabs-custom-creative.png';
import GaTabImageSustainableSupplyURL_webp from './good-ad-tabs-custom-creative.webp';
import GaTabImageImpactIconURL from './good-ad-tabs-impact-icon.png';
import GaTabImageImpactIconURL_webp from './good-ad-tabs-impact-icon.webp';
import GaTabImageBetterTargetingURL from './good-ad-tabs-better-targeting.png';
import GaTabImageBetterTargetingURL_webp from './good-ad-tabs-better-targeting.webp';
import GaTabImageNpoMatchmakingURL from './good-ad-tabs-npo-matchmaking.png';
import GaTabImageNpoMatchmakingURL_webp from './good-ad-tabs-npo-matchmaking.webp';

import useMediaQuery from '../../../util/useMediaQuery';

const GA_PROMO_TABS = {
  IMPACT_ICON: 0,
  SUSTAINABLE_SUPPLY: 1,
  BETTER_TARGETING: 2,
  NPO_MATCHMAKING: 3,
};

const SustainableSupplyTab = () => {
  return (
    <div className={css['feature-block-2022-tab-content']}>
      <picture>
        <source srcSet={GaTabImageSustainableSupplyURL_webp} type="image/webp" />
        <img src={GaTabImageSustainableSupplyURL} aria-hidden="true" width="464" height="348" alt="Sustainable supply sample" />
      </picture>
      <h4>
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.sustainableSupply.title" />
      </h4>
      <p>
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.sustainableSupply.subtext" />
      </p>
      <a href="https://company.givsly.com/advertising/">
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.sustainableSupply.link" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1C1A34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </a>
    </div>
  );
};

const ImpactIconTab = () => {
  return (
    <div className={css['feature-block-2022-tab-content']}>
      <picture>
        <source srcSet={GaTabImageImpactIconURL_webp} type="image/webp" />
        <img src={GaTabImageImpactIconURL} aria-hidden="true" width="464" height="348" alt="Impact icon sample"/>
      </picture>
      <h4>
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.impactCreatives.title" />
      </h4>
      <p>
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.impactCreatives.subtext" />
      </p>
      <a href="https://company.givsly.com/advertising/">
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.impactCreatives.link" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1C1A34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </a>
    </div>
  );
};

const BetterTargetingTab = () => {
  return (
    <div className={css['feature-block-2022-tab-content']}>
      <picture>
        <source srcSet={GaTabImageBetterTargetingURL_webp} type="image/webp" />
        <img src={GaTabImageBetterTargetingURL} aria-hidden="true" width="464" height="348"  alt="better targeting sample" />
      </picture>
      <h4>
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.betterTargeting.title" />
      </h4>
      <p>
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.betterTargeting.subtext" />
      </p>
      <a href="https://company.givsly.com/advertising/">
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.betterTargeting.link" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1C1A34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </a>
    </div>
  );
};


const NpoMatchmakingTab = () => {
  return (
    <div className={css['feature-block-2022-tab-content']}>
      <picture>
        <source srcSet={GaTabImageNpoMatchmakingURL_webp} type="image/webp" />
        <img src={GaTabImageNpoMatchmakingURL} aria-hidden="true" width="464" height="348"  alt="nonprofit matching sample" />
      </picture>
      <h4>
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.npoMatchmaking.title" />
      </h4>
      <p>
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.npoMatchmaking.subtext" />
      </p>
      <a href="https://company.givsly.com/advertising/">
        <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.npoMatchmaking.link" />
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 12L10 8L6 4"
            stroke="#1C1A34"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </a>
    </div>
  );
};


const ActiveTab = ({ tab }) => {
  switch (tab) {
    case GA_PROMO_TABS.IMPACT_ICON:
      return <ImpactIconTab />;
    case GA_PROMO_TABS.SUSTAINABLE_SUPPLY:
      return <SustainableSupplyTab />;
    case GA_PROMO_TABS.BETTER_TARGETING:
        return <BetterTargetingTab />;
    case GA_PROMO_TABS.NPO_MATCHMAKING:
      return <NpoMatchmakingTab />;
    default:
      return null;
  }
};

const LandingPageFeatureGoodAdvertising = () => {
  const [activeTab, setActiveTab] = useState(GA_PROMO_TABS.IMPACT_ICON);
  const showTabs = useMediaQuery('(min-width: 768px)');

  return (
    <div className={css['feature-block-2022']}>
      <div className={css['feature-block-2022-row']}>
        <div className={css['feature-block-2022-item']}>
          <picture>
            <source srcSet={GaPromoImageURL_webp} type="image/webp" />
            <img src={GaPromoImageURL} 
             aria-hidden="true" width="528" height="528" 
             alt='Promotion for responsible advertising' />
          </picture>
        </div>
        <div className={css['feature-block-2022-item']}>
          <h2>
            <FormattedMessage id="LandingPage.feature.goodAdvertising.title" />
          </h2>
          <p>
            <FormattedMessage id="LandingPage.feature.goodAdvertising.subtext" />
          </p>
          <a
            href="https://company.givsly.com/advertising/"
            target="_blank" rel="noopener noreferrer"
            className={css['section-2022-link']}
          >
            <SecondaryButton className={css['section-2022-link-button']}>
              <FormattedMessage id="LandingPage.feature.goodAdvertising.link" />
            </SecondaryButton>
          </a>
        </div>
      </div>
      {showTabs && (
        <div className={css['feature-block-2022-row']}>
          <div className={css['feature-block-2022-item']}>
            <h3>
              <FormattedMessage id="LandingPage.feature.goodAdvertising.tabs.title" />
            </h3>
            <ul className={css['feature-block-2022-tabs']}>

              <li
                className={classNames({
                  [css['feature-block-2022-tab']]: true,
                  [css['feature-block-2022-tab-active']]: activeTab === GA_PROMO_TABS.IMPACT_ICON,
                })}
              >
                <button type="button" aria-label="Link to impact creatives" onClick={() => setActiveTab(GA_PROMO_TABS.IMPACT_ICON)}>
                  <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.2 14.4H0L9.4 0H11.2L8.8 9.6H16.4857L6.6 24H4.8L7.2 14.4Z" fill="#1C1A34"/>
                  </svg>

                  <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.impactCreatives.label" />
                </button>
              </li>
              
              <li
                className={classNames({
                  [css['feature-block-2022-tab']]: true,
                  [css['feature-block-2022-tab-active']]:
                    activeTab === GA_PROMO_TABS.SUSTAINABLE_SUPPLY,
                })}
              >
                <button
                  type="button"
                  onClick={() => setActiveTab(GA_PROMO_TABS.SUSTAINABLE_SUPPLY)}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 8.5C2 6.1 3.01809 1.5 5.56332 0C5.58685 0.0462273 5.61202 0.098064 5.63966 0.15501C6.01068 0.919314 6.8285 2.60401 10.1447 4C11.4084 4.53194 12.568 4.7636 13.6855 4.98684C15.9039 5.43 17.956 5.83996 20.3257 8.5C22.1061 10.4987 22.787 14.0339 20.7565 16.9415C19.7483 15.1332 18.1846 13.1985 15.7442 12C11.7041 10.0158 9.46938 8.83652 8.10853 7.49993C7.38468 6.78898 6.67562 6.73191 6.53729 7.17292C7.44326 8.83228 9.7096 10.6683 13.6543 12.6055C16.4875 13.997 18.1928 15.7752 19.1653 17.8015C20.1242 19.7993 20.3256 21.948 20.3256 24H17.2714C17.2714 22.5813 17.1664 21.2424 16.8127 20.0014C10.4596 22.3508 2 16.4 2 8.5Z" fill="#1C1A34"/>
                  </svg>
                  <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.sustainableSupply.label" />
                </button>
              </li>

              <li
                className={classNames({
                  [css['feature-block-2022-tab']]: true,
                  [css['feature-block-2022-tab-active']]:
                    activeTab === GA_PROMO_TABS.BETTER_TARGETING,
                })}
              >
                <button type="button" aria-label="Link to better targeting" onClick={() => setActiveTab(GA_PROMO_TABS.BETTER_TARGETING)}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_3370_19718)">
                  <path fillRule="evenodd" clipRule="evenodd" d="M19 0C18.4477 0 18 0.447716 18 1V23C18 23.5523 18.4477 24 19 24H23C23.5523 24 24 23.5523 24 23V1C24 0.447715 23.5523 0 23 0H19ZM9 13C9 12.4477 9.44772 12 10 12H14C14.5523 12 15 12.4477 15 13V23C15 23.5523 14.5523 24 14 24H10C9.44772 24 9 23.5523 9 23V13ZM0 19C0 18.4477 0.447715 18 1 18H5C5.55228 18 6 18.4477 6 19V23C6 23.5523 5.55228 24 5 24H1C0.447715 24 0 23.5523 0 23V19Z" fill="#1C1A34"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_3370_19718">
                  <rect width="24" height="24" fill="white"/>
                  </clipPath>
                  </defs>
                  </svg>

                  <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.betterTargeting.label" />
                </button>
              </li>

              <li
                className={classNames({
                  [css['feature-block-2022-tab']]: true,
                  [css['feature-block-2022-tab-active']]:
                    activeTab === GA_PROMO_TABS.NPO_MATCHMAKING,
                })}
              >
                <button type="button" aria-label="Link to nonprofit matching" onClick={() => setActiveTab(GA_PROMO_TABS.NPO_MATCHMAKING)}>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_3371_19746)">
                    <path d="M7.81396 12.686L7.23649 12.1086C4.46649 9.33856 4.46649 4.8475 7.23649 2.0775C10.0065 -0.692501 14.4976 -0.692501 17.2676 2.0775C20.0376 4.8475 20.0376 9.33856 17.2676 12.1086L5.38093 23.9952L2 20.582C5.87903 16.6145 9.73777 12.6256 13.6415 8.68235C14.4089 7.91496 14.4089 6.67079 13.6415 5.90339C12.8741 5.136 11.6299 5.136 10.8625 5.90339C10.0951 6.67079 10.0951 7.91498 10.8625 8.68238L11.3401 9.15994L7.81396 12.686Z" fill="#1C1A34"/>
                    <path d="M13.1926 18.0647L19.1279 24L22.7509 20.3127L22.7453 20.3071L22.7508 20.3014L16.7368 14.5172C15.9915 15.2675 14.5499 16.716 13.1926 18.0647Z" fill="#1C1A34"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_3371_19746">
                    <rect width="24" height="24" fill="white"/>
                    </clipPath>
                    </defs>
                  </svg>
                  <FormattedMessage id="LandingPage.feature.goodAdvertising.tab.npoMatchmaking.label" />
                </button>
              </li>
            </ul>
          </div>

          <div className={css['feature-block-2022-item']}>
            <ActiveTab tab={activeTab} />
          </div>
        </div>
      )}
      {!showTabs && (
        <div className={css['feature-block-2022-row']}>
          <div className={css['feature-block-2022-item']}>
            <h3>
              <FormattedMessage id="LandingPage.feature.goodAdvertising.tabs.title" />
            </h3>
          </div>
          <div className={css['feature-block-2022-item']}>
            <ImpactIconTab />
            <SustainableSupplyTab />
            <BetterTargetingTab />
            <NpoMatchmakingTab />
          </div>
        </div>
      )}
    </div>
  );
};

export default LandingPageFeatureGoodAdvertising;
