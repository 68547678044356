import React from 'react';
import css from './OutreachRequests.css';
import classNames from 'classnames';
import Menu from '../Menu/Menu';
import MenuLabel from '../MenuLabel/MenuLabel';
import MenuContent from '../MenuContent/MenuContent';
import MenuItem from '../MenuItem/MenuItem';
import LiteButton from '../LiteButton/LiteButton';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { NO_ICON, STYLE_SUCCESS, STYLE_LIGHT } from '../LiteButton/constants';
// @ts-ignore
import moment from 'moment-timezone';

const STATE_PENDING = 'PENDING';
const STATE_CONFIRMED = 'CONFIRMED';
const STATE_APPROVED = 'APPROVED';

const DATE_TIME_FORMAT = 'MMM D, h:mm a';

type OutreachRequestRowProps = {
  getListing: (id: string) => object;
  nonprofitNameMap: { [key: string]: string };
  onDiscardRequest: (id: string) => void;
  onArchiveRequest: (id: string) => void;
  onDeleteRequest: (id: string) => void;
  onProcessRequest: (id: string) => void;
  onViewRequestDetails: (id: string) => void;
  outreachOffer: OutreachOffer;
  outreachRequest: OutreachRequest;
  timezone: string;
};

const OutreachRequestRow = (props: OutreachRequestRowProps) => {
  const {
    nonprofitNameMap,
    outreachRequest,
    outreachOffer,
    onDiscardRequest,
    onArchiveRequest,
    onProcessRequest,
    onDeleteRequest,
    onViewRequestDetails,
    timezone,
  } = props;
  const amount = (Number(outreachRequest.donationAmount) / 100).toFixed(2);
  const createdAt = moment(outreachRequest.createdAt).tz(timezone);
  const updatedAt = moment(outreachRequest.updatedAt).tz(timezone);
  const nonprofitName = nonprofitNameMap[outreachRequest.selectedNonProfitId];
  const isPending = outreachRequest.state === STATE_PENDING;
  const isPendingAndreachedCap = outreachOffer.state === 'capReached' && isPending;

  const menuItems = outreachRequest.archived ? (
    <MenuContent hideArrow={true} className={css.menuContent}>
      <MenuItem
        className={classNames(css.menuItem, css.menuItemDetails)}
        key="details"
        onClick={() => onViewRequestDetails(outreachRequest.id)}
      >
        View details
      </MenuItem>
    </MenuContent>
  ) : outreachRequest.state === 'PENDING' ? (
    <MenuContent hideArrow={true} className={css.menuContent}>
      <MenuItem
        className={classNames(css.menuItem, css.menuItemDetails)}
        key="details"
        onClick={() => onViewRequestDetails(outreachRequest.id)}
      >
        View details
      </MenuItem>
    </MenuContent>
  ) : (
    <MenuContent hideArrow={true} className={css.menuContent}>
      <MenuItem
        className={classNames(css.menuItem, css.menuItemDetails)}
        key="details"
        onClick={() => onViewRequestDetails(outreachRequest.id)}
      >
        View details
      </MenuItem>
      <MenuItem
        className={classNames(css.menuItem, css.menuItemArchive)}
        key="archive"
        onClick={() => onArchiveRequest(outreachRequest.id)}
      >
        Archive
      </MenuItem>
    </MenuContent>
  );

  return (
    <tr className={classNames(css.offer, isPending ? css.offerPending : null)}>
      <td className={css.nameCol}>
        <span className={css.name}>
          {outreachRequest.bookerFirstName} {outreachRequest.bookerLastName}
          {!outreachRequest.bookerFirstName &&
            !outreachRequest.bookerLastName &&
            outreachRequest.bookerEmail}
          {!outreachRequest.bookerFirstName &&
            !outreachRequest.bookerLastName &&
            !outreachRequest.bookerEmail &&
            'Anonymous'}
          {outreachRequest.bookerMessage && outreachRequest.bookerMessage.length && (
            <span className={css.bookerMessageIcon} />
          )}
          {outreachRequest.state === STATE_APPROVED && (
            <span className={css.approved}>Approved</span>
          )}
        </span>
        <span className={css.foot}>
          {createdAt.format(DATE_TIME_FORMAT)} &bull; {outreachOffer.name}
        </span>
      </td>
      <td className={css.donationCol}>
        <span className={css.donation}>$ {amount}</span>
        {isPendingAndreachedCap && (
          <Tooltip
            content={
              <div>
                <p className={css.tippyHeader}>Donation budget cap reached</p>
                <p className={css.tippyIngress}>
                  You have already reached the budget cap for this Donation Offer link. If you wish
                  to exceed the cap, continue to the payment details to process the offer.
                </p>
              </div>
            }
            icon={
              <svg className={css.svgTip} width="12" height="12" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fillRule="evenodd">
                  <circle fill="red" cx="6" cy="6" r="6" />
                  <path
                    d="M6.5 6.5A.5.5 0 0 0 7 6V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V6a.5.5 0 0 0 .5.5h1zM6 10a1.25 1.25 0 1 0 0-2.5A1.25 1.25 0 0 0 6 10z"
                    fill="#FFF"
                  />
                </g>
              </svg>
            }
          ></Tooltip>
        )}
        <span className={css.foot}>{nonprofitName}</span>
      </td>
      <td className={css.statusCol}>
        {outreachRequest.state === STATE_PENDING || outreachRequest.state === STATE_APPROVED ? (
          <span className={css.controls}>
            <LiteButton
              className={css.button}
              onClick={() => onProcessRequest(outreachRequest.id)}
              style={STYLE_SUCCESS}
              icon={NO_ICON}
            >
              Pay
            </LiteButton>
            <LiteButton
              className={css.button}
              onClick={() => onDiscardRequest(outreachRequest.id)}
              style={STYLE_LIGHT}
              icon={NO_ICON}
            >
              Discard
            </LiteButton>
          </span>
        ) : outreachRequest.state === STATE_CONFIRMED ? (
          outreachRequest.archived ? (
            <>
              <span className={classNames(css.status, css.archived)}>Paid</span>
              <span className={css.foot}>
                Archived
                <br />
                {updatedAt.format(DATE_TIME_FORMAT)}
              </span>
            </>
          ) : (
            <>
              <span className={classNames(css.status, css.confirmed)}>Paid</span>
              <span className={css.foot}>{updatedAt.format(DATE_TIME_FORMAT)}</span>
            </>
          )
        ) : outreachRequest.archived ? (
          <>
            <span className={classNames(css.status, css.archived)}>Discarded</span>
            <span className={css.foot}>
              Archived
              <br />
              {updatedAt.format(DATE_TIME_FORMAT)}
            </span>
          </>
        ) : (
          <>
            <span className={classNames(css.status, css.discarded)}>Discarded</span>
            <span className={css.foot}>{updatedAt.format(DATE_TIME_FORMAT)}</span>
          </>
        )}
      </td>
      <td className={css.controlsCol}>
        <Menu>
          <MenuLabel className={css.menuLabel}>&hellip;</MenuLabel>
          {menuItems}
        </Menu>
      </td>
    </tr>
  );
};

type OutreachRequestsProps = {
  getListing: (id: string) => object;
  nonprofitNameMap: { [key: string]: string };
  onDeleteRequest: (id: string) => void;
  onDiscardRequest: (id: string) => void;
  onArchiveRequest: (id: string) => void;
  onProcessRequest: (id: string) => void;
  onViewRequestDetails: (id: string) => void;
  outreachRequests: OutreachRequest[];
  outreachOffers: OutreachOffer[];
  timezone: string;
};

const OutreachRequests = (props: OutreachRequestsProps) => {
  const {
    getListing,
    nonprofitNameMap,
    onDeleteRequest,
    onDiscardRequest,
    onArchiveRequest,
    onProcessRequest,
    onViewRequestDetails,
    outreachRequests,
    outreachOffers,
    timezone,
  } = props;

  return outreachOffers && outreachOffers.length && outreachRequests && outreachRequests.length ? (
    <div className={css.root}>
      <section className={css.outreachRequests}>
        <table className={css.offers}>
          <tbody>
            {outreachRequests.map((request: any) => {
              const offer: any = outreachOffers.filter(
                (offer: OutreachOffer) => offer.id === request.outreachOfferId
              )[0];
              return offer && request ? (
                <OutreachRequestRow
                  getListing={getListing}
                  key={`req-${request.id}`}
                  nonprofitNameMap={nonprofitNameMap}
                  onDeleteRequest={onDeleteRequest}
                  onDiscardRequest={onDiscardRequest}
                  onArchiveRequest={onArchiveRequest}
                  onProcessRequest={onProcessRequest}
                  onViewRequestDetails={onViewRequestDetails}
                  outreachRequest={request}
                  outreachOffer={offer}
                  timezone={timezone}
                />
              ) : null;
            })}
          </tbody>
        </table>
      </section>
    </div>
  ) : null;
};

export default OutreachRequests;
